.listgrup{
  background-color: blue;
}
.cardname{
  font-size: 22px;
  color: #427BFF;
}
.maincard{
  box-shadow: cornflowerblue;
}
.shadow{
  border-radius: 10px!important;
  border: 1px solid #fff !important;
  text-align: center;

}
.cardpointer{
  cursor: pointer!important;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0 2px 8px 0 rgba(91,155,213, 0.2);
}
.cardpointer:hover{
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(91,155,213, 0.5);
}
.membertypediv{
  border-radius: 25px;
}
.review-card{
  border-top: 1px solid rgba(0, 0, 0, 0.562)!important;
  width:100%;
  margin-top: 20px!important;
  margin-bottom: 25px!important;
}
.togglebtn{
  float: right!important;
  background-color: #5B9BD5!important;
  border: 0px !important;
  color: #fff!important;
  margin-top: 3px!important;
  
}
.togglebtn::selection{
  border: 0px !important;
}
.sssheadtag{
  font-size: 20px!important;
  font-weight: bold;
}
.star{
  width: 7%!important;
}
.margintop{
  padding-top: 20px!important;
}
.btn-update{
  width: 50%!important;

}
#dd-serv-seek-type{
  width: auto!important;
}
.form-check-input {
  margin-top: 0.5rem!important;
}
.bgcoolors{
  background-color: #F4F5F7!important;
  padding-bottom: 50px!important;
}
.labelcolor{
  color: #333!important;
}
.togglebtn2:focus, .togglebtn2:active{
  outline: none !important;
  box-shadow: none !important;
}
.togglebtn:focus, .togglebtn:active{
  outline: none !important;
  box-shadow: none !important;
}
.blist:hover{
  background-color: #427BFF;
  color: #fff;
}
.blist{
  transition: 0.4s;
}
.tdlist{
  text-align: left;
}
.sppheader{
  background-color: #3A3A3A;
  color: #fff;
  border-top-left-radius:10px!important;
  border-top-right-radius:10px!important;
}
.page-subtitle {
  letter-spacing: .125rem;
  color: #818ea3;
  font-size: .625rem;
}
.page-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.bottomcard{
  margin-top: 20px;
}
.modell{
  border-radius: 2%;
}
.intext {
  text-transform: uppercase;
}
.border-top{
  border-top: 1px solid #5B9BD5!important;
  border-bottom: 1px solid #5B9BD5!important;
}